import { baseUrlWithToken, baseUrlWithoutToken } from './baseUrl';
import { history } from '../index';
import swal from 'sweetalert';

export const registration = async (data) => {
  try {
    await baseUrlWithoutToken.get('roles?filter[name][_eq]=app_users').then(async (roleResponse) => {
      console.warn("roleResponse >> ", roleResponse);
      data.role = roleResponse.data.data[0].id;
      await baseUrlWithoutToken.post('users/', data).then((data) => {
        swal('Başarıyla kayıt oldun', 'Giriş sayfasına yönlendiriliyorsun', 'success');
        setTimeout(() => {
          history.push('/login');
        }, [1500]);
      });
    })
  } catch (error) {
    console.warn("error >> ", error);
    //Geçici olarak hatalar kapatıldı.
    swal('Bir sorun olustu', '', 'warning');
    // swal('Başarıyla kayıt oldun', 'Ana sayfaya yönlendiriliyorsun', 'success');
    // setTimeout(() => {
    //   history.push('/home');
    // }, [1500]);
  }
};

export const loginRequest = (userInfo) => {
  try {
    baseUrlWithoutToken.post('/auth/login/', userInfo).then((tokens) => {
      console.warn("tokens >> ", tokens);
      localStorage.setItem('refresh_token', tokens.data.data.refresh_token);
      localStorage.setItem('token', tokens.data.data.access_token)
      console.warn("localStorage.getItem('token') >> ", localStorage.getItem('token'));
      // getUser().then((user) => {
        // localStorage.setItem('userId', user.id);
        history.push('/home');
      // })
    });
  } catch (error) {
    console.warn("error >> ", error);
    swal('Kullanıcı adı veya şifren yanlış', 'Ama hangisi söylemem', 'warning');
  }
};

export const sendScore = async () => {
  const score = localStorage.getItem('score') || null;
  const time = localStorage.getItem('time') || null;
  if (score && time) {
    await baseUrlWithToken.post('/items/scores/', {
      value: JSON.parse(score),
      time: JSON.parse(time),
    });
    setTimeout(() => {
      localStorage.removeItem('score');
      localStorage.removeItem('time');
    }, 3000);
  }
};


const getUser = (callback) => {
  baseUrlWithToken.get('/users/me/').then((user) => {
    console.warn("user >> ", user.data.data);
    localStorage.setItem('userId', user.data.data.id);
    callback(user.data.data);
  });

}

export const fetchUserInfo = (callback) => {
  getUser(callback);
};

export const changePassword = async (requestData) => {
  try {
    await baseUrlWithToken.patch('/users/me/', requestData).then(() => {
      swal('İşlemin başarılı.', '', 'success');
    });
  } catch {
    swal('Üzgünüm bir sorun oluştu.', '', 'warning');
  }
};

export const updateProfile = async (requestData) => {
  try {
    await baseUrlWithToken.patch('/users/me', requestData).then(() => {
      swal('İşlemin başarılı.', '', 'success');
    });
  } catch {
    swal('Üzgünüm bir sorun oluştu.', '', 'warning');
  }
};

export const resetPasswordClaim = async (requestData) => {
  try {
    await baseUrlWithoutToken.post('/auth/password/request/', requestData).then(() => {
      swal('İşlemin başarılı.', '', 'success');
    });
  } catch {
    swal('Üzgünüm bir sorun oluştu.', '', 'warning');
  }
};

export const resetPassword = async (requestData) => {
  try {
    await baseUrlWithoutToken.post('/auth/password/reset/', requestData).then(() => {
      swal('İşlemin başarılı.', '', 'success');
      setTimeout(() => {
        history.push('/login');
      }, [1500]);
    });
  } catch {
    swal('Üzgünüm bir sorun oluştu.', '', 'warning');
  }
};

export const fetchUserScore = async () => {
  const { data } = await baseUrlWithToken.get('/items/scores/');
  return data;
};
