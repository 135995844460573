import React,{useState} from 'react';
import facebook from '../../assets/images/facebook.svg';
import insta from '../../assets/images/insta.svg';
import youtube from '../../assets/images/youtube.svg';
import {FacebookFilled, TwitterCircleFilled, InstagramFilled} from '@ant-design/icons';
import TermsModal from "../../components/modals/TermsModal/TermsModal"
import InfoModal from "../../components/modals/InfoModal/InfoModal"


const Footer = () => {
  const [showTerms, setShowTerms] = useState(false);
  const toggleTermShow = () => setShowTerms(p => !p);
  const [showInfo, setShowInfo] = useState(false);
  const toggleInfoShow = () => setShowInfo(p => !p);
  return (
    <>
      <div className="footer">
        <div className='footer-left'>
          <a href="https://www.youtube.com/watch?v=nB_H0ndK34I" target="_blank">
            <img className="footer-icon" src={youtube} />
          </a>
          <a href="https://instagram.com/icim?igshid=1nvew6ogu5b26" target="_blank">
            <img className="footer-icon" src={insta} />
          </a>
          <a href="https://www.facebook.com/icim/" target="_blank">
            <img className="footer-icon" src={facebook} />
          </a>
        </div>
        <div className='footer-right'>
          <a href="#kullanim-kosullari" href="javascript:void(0)" onClick={toggleTermShow}>Kullanım Koşulları
          </a> | 
          <a href="#aydinlatma-metni" href="javascript:void(0)" onClick={toggleInfoShow}>Aydınlatma Metni
          </a>  | 
          <a href="https://www.akgida.com.tr/6-iletisim/" target="_blank">Bize Ulaşın
          </a> | 
          <a href="https://www.akgida.com.tr/1-hakkimizda/" target="_blank">Hakkımızda 
          </a>
        </div>
        <TermsModal showTerms={showTerms} toggleShow={toggleTermShow}></TermsModal>
        <InfoModal showInfo={showInfo} toggleShow={toggleInfoShow}></InfoModal>
      </div>
    </>
  );
};

export default Footer;
