import React, {useEffect, useState} from 'react';
import logoSvg from '../../assets/images/logo.svg';
import menuIcon from '../../assets/images/menuIcon.svg';
import {Link} from 'react-router-dom';
import exitIcon from '../../assets/images/exitIcon.svg';
import homeIcon from '../../assets/images/homeIcon.svg';
import profileIcon from '../../assets/images/profileIcon.svg';
import starIcon from '../../assets/images/starIcon.svg';
import {history} from '../../index';
import {fetchUserInfo} from '../../apis/utils';
import {UnlockFilled} from '@ant-design/icons';

const Header = ({isVisible, isAuth, isGamingPage}) => {
  const [username, setUsername] = useState('');
  const handleButtonClick = () => {
    let popup = document.getElementById('popup-menu');
    popup.classList.toggle('show');
  };
  const token = localStorage.getItem('token');

  const gotoLogin = () => {
    history.push('/login');
  };

  const gotoRegister = () => {
    history.push('/register');
  };

  const logOut = () => {
    localStorage.removeItem('token');
    history.push('/login');
  };

  useEffect(() => {
    if (token) {
      fetchUserInfo((data) => {
        console.warn("data >> ", data);
        setUsername(data.full_name);
      });
    }
  }, []);

  return (
    <>
      {isVisible && (
        <div className="header">
          <a className="text-white text-fs18" href="/home">
            <img className="logo" alt="logo" src={logoSvg} />
          </a>
          {isAuth ? (
            <div className="m-4 d-flex align-items-center">
              <span className="mr-2 text-lightpurple text-fs18">
                {username.toUpperCase()}
              </span>
              <button onClick={handleButtonClick} className="button button__menu">
                <img style={{width: '35px'}} src={menuIcon} />
                <div className="popup">
                  <div id="popup-menu" className="popuptext d-flex flex-column">
                    <Link to="/profile" className="text-white text-fs18">
                      <img className="mr-2 width-18" src={profileIcon} />
                      Bilgilerimi Güncelle
                    </Link>
                    <Link to="/point_table" className="text-white text-fs18">
                      <img className="mr-2 width-18" src={starIcon} />
                      Puan Tablosu
                    </Link>
                    <Link to="/home" className="text-white text-fs18">
                      <img className="mr-2 width-18" src={homeIcon} />
                      Anasayfaya Git
                    </Link>
                    <Link
                      to="/password_change"
                      className="text-white text-fs18 d-flex align-items-end"
                    >
                      <UnlockFilled className="mr-2" />
                      Şifremi Değiştir
                    </Link>
                    <button
                      onClick={logOut}
                      className="button mt-1 p-0 text-left text-white text-fs18"
                    >
                      <img className="mr-2 width-18" src={exitIcon} />
                      Çıkış Yap
                    </button>
                  </div>
                </div>
              </button>
            </div>
          ) : (
            <div>
              <button
                onClick={gotoLogin}
                className={`button button-header ${
                  isGamingPage ? 'text-white' : 'text-lightpurple'
                }`}
              >
                Giriş yap
              </button>
              <button
                onClick={gotoRegister}
                className={`button button-header ${
                  isGamingPage ? 'text-white' : 'text-lightpurple'
                }`}
              >
                Kayıt ol
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

Header.defaultProps = {
  isVisible: true,
};

export default Header;
