import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';


const TermsModal = (props) => {
    const { showTerms, toggleShow } = props
    return (
        <Modal className="terms-modal" show={showTerms} onHide={toggleShow}>
            <Modal.Header closeButton>
                <Modal.Title>KULLANIM KOŞULLARI
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div>
                    
  
                <p>Bu sitede gezinmeniz, merkezi "<em>Esas Maltepe Ofis Park, Altay&ccedil;eşme Mahallesi E5 Karayolu &Uuml;zeri &Ccedil;amlı Sokak No: 21 Maltepe-İstanbul</em>" adresinde bulunan ve İstanbul Ticaret Sicil M&uuml;d&uuml;rl&uuml;ğ&uuml; nezdinde 349976 sicil numarasıyla kayıtlı olan <strong>Ak Gıda Sanayi ve Ticaret Anonim Şirketi</strong> (bundan sonra "<strong>Ak Gıda</strong>"olarak anılacaktır) tarafından İ&ccedil;imino &Ccedil;ocuk Oyunu ile ilgili olarak sunulan bu genel kullanım şart ve koşullarına (bundan sonra "<strong>Kullanım Koşulları</strong>"olarak anılacaktır) tabidir.&nbsp;</p>
                <ul>
                    <li><strong><strong>Kullanım Koşulları'nın Kabul&uuml; ve Sorumluluk</strong></strong></li>
                </ul>
                <p><a href="https://iciminoileoyna.com/home">https://iciminoileoyna.com/home</a> (bundan sonra "<strong>Oyun Sitesi</strong>" olarak anılacaktır) sitesine erişim burada belirtilen mevcut Kullanım Koşulları'nın kendiliğinden ve sınırsız kabul&uuml; anlamına gelir. Eğer 18 yaşından k&uuml;&ccedil;&uuml;kseniz bu siteyi kullanmanız, işbu Kullanım Koşulları'nı yasal temsilciniz (veli ya da vasiniz) ile beraber incelediğiniz ve yasal temsilcinizin Kullanım Koşulları'nı sizin adınıza kabul ettiği ve onayladığı anlamına gelmektedir. Aksi halde Oyun Sitesi ve y&ouml;neticilerinin bir sorumluluğu olmayacağını bildiğinizi kabul, beyan ve taahh&uuml;t etmektesiniz. Kullanım Koşulları'nda belirtilen bildirim ve yasalara aykırı kullanım sebebiyle doğabilecek hukuki, cezai, idari ve mali her t&uuml;rl&uuml; sorumluluk kullanıcıya ve/veya yasal temsilcisine aittir.</p>
                <p>Yasal temsilciler, reşit olmayan kişilerin (&ccedil;ocukların) Oyun Sitesi &uuml;zerinde y&uuml;r&uuml;tt&uuml;ğ&uuml; t&uuml;m faaliyetlerini yakından izlemek ve gereken m&uuml;dahaleleri g&ouml;stermekle tek başına y&uuml;k&uuml;ml&uuml; olup, bu kapsamda doğacak herhangi bir zarardan Ak Gıda sorumlu olmayacaktır.</p>
                <p>Yasal temsilciler, reşit olmayanlara ait verilecek bilgilerin doğruluğundan sorumludur. İşbu bilgilerin yanlış, eksik hatalı olması ve/veya g&uuml;ncel olmaması halinde ortaya &ccedil;ıkabilecek hak kayıpları da d&acirc;hil t&uuml;m zararlardan yasal temsilciler sorumludur.</p>
                <p>Kullanıcı ve/veya yasal temsilcisi, Kullanım Koşulları'nda belirtilen y&uuml;k&uuml;ml&uuml;l&uuml;klere aykırı hareketi nedeniyle Ak Gıda'nın uğrayacağı her t&uuml;rl&uuml; zararı aynen tazmin edecek olup, Ak Gıda'nın &ouml;demek zorunda kalabileceği her t&uuml;rl&uuml; tazminat ve/veya idari/adli para cezaları i&ccedil;in kendisine r&uuml;cu edilebileceğini bilmekte ve kabul etmektedir.</p>
                <ul>
                    <li><strong><strong>Oyun Sitesi'ne Erişim&nbsp;</strong></strong></li>
                </ul>
                <p>Kullanıcı aşağıdaki şekillerden birini tercih ederek Oyun Sitesi'ne erişim sağlayabilir:&nbsp;</p>
                <ul>
                    <li><a href="https://iciminoileoyna.com/home">https://iciminoileoyna.com/home</a> sitesine internet &uuml;zerinden bağlanmak&nbsp;</li>
                    <li>Instagram veya Facebook &uuml;zerinden erişim</li>
                </ul>
                <p>Oyun Sitesi'nin i&ccedil;eriğine erişim &uuml;cretsizdir ve herhangi bir kayıt koşulu bulunmamaktadır. Ancak kullanıcıların d&uuml;zenlenebilecek yarışmalara katılma hakkı elde edebilmesi i&ccedil;in Oyun Sitesi'ne kayıt olmaları gerekmektedir. İnternet bağlanma maliyetleri kullanıcılar tarafından karşılanacaktır.&nbsp;</p>
                <p>Kayıt s&uuml;recinin sağlıklı ve hukuka uyun bir bi&ccedil;imde ilerlemesi i&ccedil;in ilgili bilgileri doğru ve eksiksiz bir bi&ccedil;imde girmeli ve hesabınızı kimseyle paylaşmamalısınız. Ak Gıda, işbu Kullanım Koşulları'nın ihlal edildiği kanaatine vardığı herhangi bir kişinin bu siteye erişimine izin vermeme hakkına sahiptir.&nbsp;</p>
                <p>Kulanım Koşulları'nda yapılan herhangi bir değişiklik sitede yayımlandığı andan itibaren ge&ccedil;erli olacaktır.</p>
                <ul>
                    <li><strong><strong>Oyun Sitesi'nin Amacı&nbsp;</strong></strong></li>
                </ul>
                <p>Site, kullanıcının İ&ccedil;imino &Ccedil;ocuk Oyunu'na katılması ve profil oluşturmasına izin verir.&nbsp;</p>
                <p>Site aynı zamanda İ&ccedil;imino &Ccedil;ocuk Oyunu kapsamında Ak Gıda tarafından d&uuml;zenlenebilecek yarışmalarda &ouml;d&uuml;l kazanma imkanı sağlar.</p>
                <ul>
                    <li><strong><strong>Ak Gıda'nın Taahh&uuml;t ve Y&uuml;k&uuml;ml&uuml;kleri </strong></strong></li>
                </ul>
                <p>Ak Gıda, Oyun Sitesi'nde bulunan bilgiler hakkında azami &ouml;zen ve dikkati g&ouml;stermektedir. Ancak Ak Gıda, Oyun Sitesi'nde bulunan bilgi ve mevcut materyallerin doğruluğunu ve eksiksizliğini garanti edemez ve bu konuda herhangi bir taahh&uuml;tte bulunmamaktadır. Bu sebeple Ak Gıda, yanlış ve/veya eksik bilgiler nedeniyle ortaya &ccedil;ıkan doğrudan ya da dolaylı zararlardan sorumlu tutulamayacaktır.&nbsp;</p>
                <p>Ak Gıda, işbu Kullanım Koşulları'nın 2. maddesinde belirtilen Oyun Sitesi'ne erişimin her zaman sağlanabilmesi i&ccedil;in t&uuml;m makul &ccedil;abayı g&ouml;sterecektir. Ancak, Oyun Sitesi'ne erişimde g&uuml;&ccedil;l&uuml;k yaşanması ya da internet bağlantısındaki sorunlar ve dahi bu gibi sebeplerle erişim sağlanamaması halinde Ak Gıda'nın herhangi bir sorumluluğu olmayacaktır.</p>
                <p>Ak Gıda, herhangi bir e-posta'nın kaybı, gecikmesi ya da hatalı bir adrese g&ouml;nderiminin yapılıp yapılmamasından sorumlu olmayacağı gibi harici internet sitelerinden kaynaklı herhangi bir zarardan dolayı da sorumlu olmayacaktır.</p>
                <p>Yukarıdaki h&uuml;k&uuml;mlere ek olarak, veri kaybı, hasar ve imha da dahil olmak &uuml;zere, Ak Gıda, Oyun Sitesi'ne erişim ya da erişimin imkansızlığından, kesintiden, arızadan, bilgisayar vir&uuml;s&uuml;nden, yetkisiz erişim gibi sebeplerden kaynaklanan doğrudan ya da dolaylı zararlardan sorumlu tutulamayacaktır.&nbsp;</p>
                <p>Ak Gıda'nın her zaman ve herhangi bir gerek&ccedil;e g&ouml;stermeksizin, tek taraflı olarak verilen hizmeti ve materyali s&uuml;rekli veya ge&ccedil;ici olarak durdurma veya iptal etme hakkı vardır.</p>
                <ul>
                    <li><strong><strong>Fikri M&uuml;lkiyet Hakları&nbsp;</strong></strong></li>
                </ul>
                <p>Kullanıcı, İ&ccedil;imino &Ccedil;ocuk Oyunu'na ait yazılımı değiştiremez, uyarlayamaz veya terc&uuml;me edemez; yazılımı veya herhangi bir kısmını kopyalayamaz kaynak koda d&ouml;n&uuml;şt&uuml;remez, tersine &ccedil;eviremez, &uuml;zerinde ters m&uuml;hendislik yapamaz veya başka şekilde okunabilir bir forma d&ouml;n&uuml;şt&uuml;remez; yazılımı bir başkasına lisans olarak veremez veya sair şekilde dağıtımını yapamaz; yazılımı bir &uuml;&ccedil;&uuml;nc&uuml; şahsa a&ccedil;ıklayamaz veya yazılımı &uuml;&ccedil;&uuml;nc&uuml; bir şahsın kullanmasına izin veremez.&nbsp;</p>
                <p>Kullanıcı, Ak Gıda&rsquo;ya ait marka, unvan, logo ve benzeri herhangi bir işaretin, tanıtım, pazarlama, reklam materyalleri dahil ve fakat bunlarla sınırlı olmamak &uuml;zere ve sosyal medya da dahil hi&ccedil;bir ortamda ticari olarak kullanmayacaktır.</p>
                <ul>
                    <li><strong><strong>Tazminat</strong></strong></li>
                </ul>
                <p>İşbu Kullanım Koşulları'nı ihlal etmeniz nedeniyle Ak Gıda ve &uuml;&ccedil;&uuml;nc&uuml; kişilerin uğrayacağı t&uuml;m zararları tazmin etmeniz gerekmektedir.&nbsp;</p>
                <ol>
                    <li><strong>Değişiklik ve G&uuml;ncelleme</strong></li>
                </ol>
                <p>Ak Gıda, Oyun Sitesi'nde ve iş bu Kullanım Koşulları'nda herhangi bir bildirimde bulunmaksızın tek taraflı değişiklik yapabilir. Oyun Sitesi'nin yenilenmiş g&uuml;ncel Kullanım Koşulları yeni tarih g&uuml;ncellemesi ile yayınlanacaktır. G&uuml;ncel Kullanım Koşulları, yayınlandığı andan itibaren ge&ccedil;erli olacak ve Oyun Sitesi'nin kullanımı o andan itibaren yenilenmiş Kullanım Koşulları'na bağlı olacaktır.</p>
                <p>Değişikliklerden haberdar olmak amacıyla Kullanım Koşulları'nı d&uuml;zenli olarak g&ouml;zden ge&ccedil;irmeyi unutmayınız.&nbsp;</p>
                <ul>
                    <li><strong><strong>Gizlilik</strong></strong></li>
                </ul>
                <p>İşbu Oyun Sitesi ile tarafımıza sağladığınız kişisel verilerinizin Aydınlatma Metnimiz uyarınca işleme tabi tutulacağını hatırlatmak isteriz.&nbsp;</p>
                <ul>
                    <li><strong><strong>Uygulanacak Hukuk ve Yargı Yeri</strong></strong></li>
                </ul>
                <p>Bu Kullanım Koşulları, T&uuml;rkiye Cumhuriyeti yasalarına tabidir, Kullanım Koşulları'nın yorumu ve uygulaması T&uuml;rkiye Cumhuriyeti yasaları uyarınca yapılacaktır. Oyun Sitesi'nin kullanılmasından, işbu Kullanım Koşulları'nda yazılı h&uuml;k&uuml;m ve hukuki ilişkilerin uygulanmasından doğabilecek uyuşmazlıkların &ccedil;&ouml;z&uuml;m&uuml;nde İstanbul (&Ccedil;ağlayan) Mahkemeleri ve İcra Daireleri yetkili kılınmıştır.</p>
                <p><br /><br /></p>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <a className="btn btn-primary" onClick={toggleShow}>
                    Tamam
                </a>
            </Modal.Footer>
        </Modal>
    )
};
export default TermsModal;
