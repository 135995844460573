import React, {useState, useEffect} from 'react';
import Header from '../components/Headers/header';
import Footer from '../components/Footers/footer';
import {Input, Button, Checkbox} from 'antd';
import profileText from '../assets/images/profileText.svg';
import PhoneInput from 'react-phone-input-2';
import {fetchUserInfo, updateProfile} from '../apis/utils';
import {isMobile} from 'react-device-detect';

const Profile = () => {
  const {TextArea} = Input;
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchUserInfo((data) => {
      setInputs({
        username: data.full_name,
        email: data.email,
        address: data.address,
      });
    });
  }, []);

  const handleInputsChange = (event) => {
    event.persist();
    if (inputs.password && inputs.password.length < 5) {
      setErrors({visibility: true, message: 'Şifreniz en az 6 karakter içermelidir'});
    } else {
      setErrors({visibility: false});
    }
    setInputs((inputs) => ({...inputs, [event.target.id]: event.target.value}));
  };

  const handleSubmit = () => {
    let data = {
      full_name: inputs.username,
      email: inputs.email,
      address: inputs.address,
    };
    if(inputs.password)
      data.password = inputs.password;

    updateProfile(data);
  };

  const handlePhoneNumberChange = (e) => {
    setInputs((inputs) => ({...inputs, phoneNumber: e}));
  };

  return (
    <>
      <div className="homepages-bg h-100">
        <Header isAuth />
        <div className="height-85 d-flex justify-content-center align-items-center flex-column">
          <div className="form__panel">
            <img className="form__panel-image" src={profileText} />
            <div className="row m-form align-items-center">
              <div className="col-md-2">
                <label className="label">İsim Soyisim</label>
              </div>
              <div className="col-md-10">
                <Input
                  id="username"
                  onChange={handleInputsChange}
                  className="form__input"
                  placeholder=""
                  value={inputs.username}
                />
              </div>
            </div>
            <div className="row m-form align-items-center width-mobile-100">
              <div className="col-md-2 p-mobile-0">
                <label className="label">Email</label>
              </div>
              <div className="col-md-10 p-mobile-0">
                <Input
                  id="email"
                  onChange={handleInputsChange}
                  className="form__input"
                  placeholder=""
                  disabled
                  value={inputs.email}
                />
              </div>
            </div>
            <div className="row m-form width-mobile-100 align-items-center">
              <div className="col-md-2 p-mobile-0">
                <label className="label">Adres</label>
              </div>
              <div className="col-md-10 p-mobile-0">
                <TextArea
                  onChange={handleInputsChange}
                  id="address"
                  className="form__input"
                  placeholder="Adres"
                  value={inputs.address}
                />
              </div>
            </div>
            <div className="d-flex flex-column m-form">
              <div>
                <label className="label">
                  Şifre
                </label>
              </div>
              <div>
                <Input
                  id="password"
                  onChange={handleInputsChange}
                  className="form__input"
                  placeholder=""
                  type="password"
                />
              </div>
            </div>
            {errors.visibility && <p>{errors.message}</p>}
            <div className="d-flex justify-content-center m-form">
              <button className="form__button" onClick={handleSubmit}>
                Kaydet
              </button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Profile;
