import {useRef} from 'react';

export const useComponentWillMount = (func) => {
  const willMount = useRef(true);

  if (willMount.current) func();

  willMount.current = false;
};

// export KeyboardClass =
//   var rightPressed = false;
//   var leftPressed = false;
//   var upPressed = false;
//   var downPressed = false;
//   var KeyboardHelper = {left: 37, up: 38, right: 39, down: 40};

//   const keyDownHandler = (event) => {
//     document.addEventListener('keydown', keyDownHandler, false);
//     if (event.keyCode == KeyboardHelper.right) {
//       rightPressed = true;
//     } else if (event.keyCode == KeyboardHelper.left) {
//       leftPressed = true;
//     }
//     if (event.keyCode == KeyboardHelper.down) {
//       downPressed = true;
//     } else if (event.keyCode == KeyboardHelper.up) {
//       upPressed = true;
//     }
//   };

//   const keyUpHandler = (event) => {
//     document.addEventListener('keyup', keyUpHandler, false);
//     if (event.keyCode == KeyboardHelper.right) {
//       rightPressed = false;
//     } else if (event.keyCode == KeyboardHelper.left) {
//       leftPressed = false;
//     }
//     if (event.keyCode == KeyboardHelper.right.down) {
//       downPressed = false;
//     } else if (event.keyCode == KeyboardHelper.right.up) {
//       upPressed = false;
//     }
//   };

//   console.log(rightPressed, leftPressed, upPressed, downPressed);
// };
