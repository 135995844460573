import React, {useEffect, useState} from 'react';
import {Input, Button} from 'antd';
import {resetPassword} from '../apis/utils';
import Header from '../components/Headers/header';
import Footer from '../components/Footers/footer';
import formHeader from '../assets/images/createNewPass.svg';

const ResetPassword = () => {
  const [inputs, setInputs] = useState({});

  const handleInputsChange = (event) => {
    event.persist();
    setInputs((inputs) => ({...inputs, [event.target.id]: event.target.value}));
  };

  const handleSubmit = () => {
    const urlParams = new URLSearchParams(window.location.search);
    let data = {
      token: urlParams.get('token'),
      password: inputs.password,
    };
    console.log(JSON.stringify(data));
    resetPassword(data);
  };

  return (
    <div className="entrance-bg h-100">
      <Header />
      <div className="height-85 mb d-flex justify-content-center align-items-center">
        <div className="form__panel">
          <img className="form__panel-image " src={formHeader} />
          <label className="label">Yeni şifre</label>
          <Input
            id="password"
            onChange={handleInputsChange}
            className="mb-3 form__input"
            type="password"
          />
          <div className="d-flex justify-content-center my-4">
            <Button className="form__button" onClick={handleSubmit}>
              Onayla
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ResetPassword;
