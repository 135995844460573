import React, {useState} from 'react';
import {Input, Button, Checkbox} from 'antd';
import {registration} from '../apis/utils';
import Header from '../components/Headers/header';
import Footer from '../components/Footers/footer';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import registerText from '../assets/images/registerText.svg';
import TermsModal from "../components/modals/TermsModal/TermsModal"
import InfoModal from "../components/modals/InfoModal/InfoModal"
import ConsentModal from "../components/modals/ConsentModal/ConsentModal"

const Register = () => {
  const {TextArea} = Input;
  const [inputs, setInputs] = useState({});
  const [terms, setTerms] = useState(false);
  const [consent, setConsent] = useState(false);
  const [contactApprove, setContactApprove] = useState(false);
  const [errors, setErrors] = useState({});
  const [emailErrors, setEmailErrors] = useState({});
  const [showTerms, setShowTerms] = useState(false);
  const toggleTermShow = () => setShowTerms(p => !p);
  const [showInfo, setShowInfo] = useState(false);
  const toggleInfoShow = () => setShowInfo(p => !p);
  const [showConsent, setShowConsent] = useState(false);
  const toggleConsentShow = () => setShowConsent(p => !p);

  document.body.classList.add("overflow-scroll");
  
  const handlePhoneNumberChange = (e) => {
    setInputs((inputs) => ({...inputs, phoneNumber: e}));
  };

  const validateEmail = (email) => {
      const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      return re.test(email);
  };

  const handleInputsChange = (event) => {
    event.persist();
    setInputs((inputs) => ({...inputs, [event.target.id]: event.target.value}));
    if (inputs.password && inputs.password.length < 5) {
      setErrors({visibility: true, message: 'Şifreniz en az 6 karakter içermelidir'});
    } else {
      setErrors({visibility: false});
    }
    console.warn("validateEmail >> ", validateEmail(inputs.email));
    if (!validateEmail(inputs.email) && event.target.id === 'email') {
      setEmailErrors({visibility: true, message: 'Geçerli bir mail adresi girin.'});
    } else {
      setEmailErrors({visibility: false});
    }
  };

  const handleSubmit = () => {
    if(!terms || !consent){
      alert('Sözleşmeleri onaylamalısınız.');
      return;
    }

    let data = {
      email: inputs.email,
      password: inputs.password,
      full_name: inputs.fullName,
      address: inputs.address,
      mothers_name : inputs.mothersName,
      fathers_name : inputs.fathersName,
      terms : terms,
      consent : consent,
      contact_approve : contactApprove,
    };

    if (inputs.password.length > 5) registration(data);
  };

  return (
    <div className="entrance-bg h-100">
      <Header />
      <div className="registration-container d-flex justify-content-center flex-column align-items-center">
        <div className="form__panel">
          <img className="form__panel-image" src={registerText} />
          <Input
            onChange={handleInputsChange}
            placeholder="Isim Soyisim"
            className="form__input my-2"
            id="fullName"
          />
          <Input
            id="email"
            onChange={handleInputsChange}
            className="form__input my-2"
            placeholder="Email"
          />
          {emailErrors.visibility && <p>{emailErrors.message}</p>}
          <Input
            id="password"
            onChange={handleInputsChange}
            className="form__input my-2"
            placeholder="Şifre"
            type="password"
          />
          {errors.visibility && <p>{errors.message}</p>}
          <Input
            id="mothersName"
            onChange={handleInputsChange}
            className="form__input my-2"
            placeholder="Anne Adı"
            type="type"
          />
          <Input
            id="fathersName"
            onChange={handleInputsChange}
            className="form__input my-2"
            placeholder="Baba Adı"
            type="type"
          />
          <TextArea
            onChange={handleInputsChange}
            id="address"
            className="form__input my-2"
            placeholder="Adres"
          />
          <Checkbox className="mr-2 text-lightpurple" onChange={()=>{
            setTerms(!terms);
          }}>
            {' '}
            <a href="javascript:void(0)" onClick={toggleTermShow}>Yarışmaya Katılım ve Kullanım Koşulları</a> ile <a href="javascript:void(0)" onClick={toggleInfoShow}>Aydınlatma Metni</a>'ni okudum, onaylıyorum. 
          </Checkbox>
          <Checkbox className="mr-2 text-lightpurple" onChange={()=>{
            setConsent(!consent);
          }}>
            {' '}
            Site içerisinde düzenlenecek yarışmalar dahilinde Aydınlatma Metninde yer alan kişisel verilerimin işlenmesine <a href="javascript:void(0)" onClick={toggleConsentShow}>açık rızam</a> vardır.
          </Checkbox>
          <Checkbox className="mr-2 text-lightpurple" onChange={()=>{
            setContactApprove(!contactApprove);
          }}>
            {' '}
            Ak Gıda'ya ait İçim markası ile ilgili tanıtım ve pazarlama içerikli e-posta almayı onaylıyorum.
          </Checkbox>
          <div className="register-button d-flex justify-content-center">
            <Button className="form__button" onClick={handleSubmit}>
              Kayıt ol
            </Button>
          </div>
        </div>
      </div>
      <TermsModal showTerms={showTerms} toggleShow={toggleTermShow}></TermsModal>
      <InfoModal showInfo={showInfo} toggleShow={toggleInfoShow}></InfoModal>
      <ConsentModal showConsent={showConsent} toggleShow={toggleConsentShow}></ConsentModal>
      <Footer />
    </div>
  );
};

export default Register;
