import React, {useState, useEffect} from 'react';
import Header from '../components/Headers/header';
import Footer from '../components/Footers/footer';
import Carousel from 'react-spring-3d-carousel';
import {LeftCircleFilled, RightCircleFilled} from '@ant-design/icons';
import {CHARACTERS} from '../helpers/constants';
import {history} from '../index';
import {sendScore} from '../apis/utils';
import splashScreen from '../assets/images/web splash_logolu_png.png';
import mobileSplashScreen from '../assets/images/mobil splash_logolu_png.png';
import DeviceOrientation, {Orientation} from 'react-screen-orientation';
import {isMobile} from 'react-device-detect';

const Characters = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isSplashVisible, setIsSplashVisible] = useState(true);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  
  const handleSelectChar = (char) => {
    history.push({
      pathname: '/gaming_page',
      state: {
        selectedCharacters: CHARACTERS[currentSlide],
      },
    });
    console.log(CHARACTERS[currentSlide]);
  };

  useEffect(() => {
    sendScore();
  }, []);

  const nextSlide = () => {
    if (currentSlide != 2) {
      setCurrentSlide((prevSlide) => prevSlide + 1);
    } else {
      setCurrentSlide(0);
    }
  };

  const prevSlide = () => {
    if (currentSlide != 0) {
      setCurrentSlide((prevSlide) => prevSlide - 1);
    } else {
      setCurrentSlide(2);
    }
  };

  const handleSplashVisibility = () => {
    if (isSplashVisible){
      setIsSplashVisible(false);
      setIsPopupVisible(true);
    } 
  };

  const slides = [
    {
      key: 0,
      content: (
        <div>
          <div className="characters-image-panel characters-pink-bg" />
          <div className="d-flex justify-content-center align-items-center mt-3" />
        </div>
      ),
    },
    {
      key: 1,
      content: (
        <div>
          <div className="characters-image-panel characters-yellow-bg" />
          <div className="d-flex justify-content-center align-items-center mt-3" />
        </div>
      ),
    },
    {
      key: 2,
      content: (
        <div>
          <div className="characters-image-panel  characters-brown-bg" />
          <div className="d-flex justify-content-center align-items-center mt-3" />
        </div>
      ),
    },
  ];

  const gotoLogin = () => {
    history.push('/login');
  };

  const gotoRegister = () => {
    history.push('/register');
  };

  return (
    <div className="h-100" onClick={handleSplashVisibility}>
      {isSplashVisible ? (
        <>
          {isMobile ? (
            <DeviceOrientation lockOrientation={'landscape'}>
              {/* Will only be in DOM in landscape */}
              <Orientation orientation="landscape" alwaysRender={false}>
                <img className="splashScreen" src={splashScreen} />
              </Orientation>
              {/* Will stay in DOM, but is only visible in portrait */}
              <Orientation orientation="portrait">
                <img className="splashScreen" src={mobileSplashScreen} />
              </Orientation>
            </DeviceOrientation>
          ) : (
            <img className="splashScreen" src={splashScreen} />
          )}
        </>
      ) : (
        <div className="newbg h-100">
          {/*isPopupVisible === true && (
            <div class="popup-overlay" onClick={()=> setIsPopupVisible(false)}>
              <div class="popup-content">
                <img src="/popup/0.jpg"/>
                </div>
            </div>
          )*/}
          <Header isVisible isAuth={localStorage.getItem('token') ? true : false} />
          <div className="height-90 d-flex flex-column justify-content-center align-items-center">
            <div className="width-60 height-carousel">
              <Carousel
                index={2}
                goToSlide={currentSlide}
                offsetRadius={3}
                slides={slides}
              />
            </div>
            {/* <div className="mt-40 mr-30"> */}
              <button className="button button-carousel" style={{
                left: isMobile ? 20 : 100,
                position: 'absolute',
                marginBottom: 100
              }} onClick={prevSlide}>
                <LeftCircleFilled />
              </button>
              <button className="button button-carousel" style={{
                right: isMobile ? 20 : 100,
                position: 'absolute',
                marginBottom: 100
              }} onClick={nextSlide}>
                <RightCircleFilled />
              </button>
            {/* </div> */}
            <div className="" style={{
              marginTop: 130,
            }}>
              <button
              style={{
                marginRight: isMobile ? 20 : 0
              }}
                onClick={handleSelectChar}
                className="button button-confirm text-fs32 button-bg-team text-darkpurple"
              >
                OYNA
              </button>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default Characters;
