import React, {useEffect, useState} from 'react';
import Header from '../components/Headers/header';
import Footer from '../components/Footers/footer';
import {Button} from 'antd';
import pointTableText from '../assets/images/pointTableText.svg';
import {StarFilled} from '@ant-design/icons';
import {fetchUserScore} from '../apis/utils';
import {format} from 'date-fns';

const PointTable = () => {
  const [record, setRecord] = useState([]);
  useEffect(() => {
    fetchUserScore().then((data) => {
      setRecord(data.data.slice(0, 5));
    });
  }, []);

  return (
    <>
      <div className="homepages-bg h-100">
        <Header isAuth />
        <div className="height-85 d-flex justify-content-center align-items-center flex-column">
          <div className="form__panel p-4">
            <img className="form__panel-image" src={pointTableText} />
            <div className="d-flex justify-content-center flex-column">
              <div className="point-table__container">
                <div className="d-flex justify-content-center align-items-center">
                  <StarFilled style={{fontSize: '32px', color: '#b594c2'}} spin={true} />
                </div>
                <div className="point-table-header-text">Süre</div>
                <div className="point-table-header-text">Tarih</div>
                <div className="point-table-header-text">Puan</div>
              </div>
              {record.map((item, index) => (
                <div key={index} className="point-table__container">
                  <div className="text-fs24 text-lightpurple d-flex justify-content-center align-items-center">
                    {index + 1}
                  </div>
                  <div className="point-table-element">{item.time}</div>
                  <div className="point-table-element">
                    {format(new Date(item.date_created), 'dd.MM.yyyy H:mm:ss')}
                  </div>
                  <div className="point-table-element">{item.score}</div>
                </div>
              ))}
            </div>
            <div className="d-flex justify-content-center my-4">
              <Button className="form__button">Anasayfaya dön</Button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default PointTable;
