import React, {useState} from 'react';
import Header from '../components/Headers/header';
import Footer from '../components/Footers/footer';
import {Input} from 'antd';
import {changePassword} from '../apis/utils';
import formHeader from '../assets/images/changePassword.svg';

const PasswordChange = () => {
  const [inputs, setInputs] = useState({});

  const handleInputsChange = (event) => {
    event.persist();
    setInputs((inputs) => ({...inputs, [event.target.id]: event.target.value}));
  };

  const handleSubmit = () => {
    let data = {
      old_password: inputs.currentPassword,
      new_password1: inputs.password,
      new_password2: inputs.repassword,
    };

    changePassword(data);
  };

  return (
    <>
      <div className="homepages-bg h-100">
        <Header isAuth />
        <div className="height-85 d-flex justify-content-center align-items-center">
          <div className="form__panel p-4">
            <img className="form__panel-image" src={formHeader} />
            <div className="row my-2 d-flex align-items-center">
              <div className="col-md-2">
                <label className="label text-fs18">Eski şifren</label>
              </div>
              <div className="col-md-10">
                <Input
                  id="currentPassword"
                  onChange={handleInputsChange}
                  className="form__input"
                  placeholder="Mevcut şifreni gir"
                  type="password"
                  value={inputs.currentPassword}
                />
              </div>
            </div>
            <div className="row my-2 d-flex align-items-center">
              <div className="col-md-2">
                <label className="label text-fs18">Yeni şifren</label>
              </div>
              <div className="col-md-10">
                <Input
                  id="password"
                  onChange={handleInputsChange}
                  className="form__input"
                  placeholder="Yeni şifreni gir"
                  type="password"
                  value={inputs.password}
                />
              </div>
            </div>
            <div className="row my-2 d-flex align-items-center">
              <div className="col-md-2">
                <label className="label text-fs18">Yeni şifreni doğrula</label>
              </div>
              <div className="col-md-10">
                <Input
                  id="repassword"
                  onChange={handleInputsChange}
                  className="form__input"
                  placeholder="Yeni şifreni tekrar gir"
                  type="password"
                  value={inputs.repassword}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center my-3">
              <button className="form__button text-fs24" onClick={handleSubmit}>
                Kaydet
              </button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default PasswordChange;
