import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';


const InfoModal = (props) => {
    const { showInfo, toggleShow } = props
    return (
        <Modal className="terms-modal" show={showInfo} onHide={toggleShow}>
            <Modal.Header closeButton>
                <Modal.Title>İÇİMİNO ÇOCUK OYUNU KAPSAMINDA KİŞİSEL VERİLERİN İŞLENMESİNE VE YURT DIŞINA AKTARILMASINA DAİR AYDINLATMA METNİ
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>"Esas Maltepe Ofis Park, Altay&ccedil;eşme Mahallesi E5 Karayolu &Uuml;zeri &Ccedil;amlı Sokak No. 21 Maltepe/İstanbul" adresinde kain ve İstanbul Ticaret Sicili M&uuml;d&uuml;rl&uuml;ğ&uuml; nezdinde 349976 sicil numarası ile kayıtlı Ak Gıda Sanayi ve Ticaret Anonim Şirketi (Bundan sonra "Ak Gıda" olarak anılacaktır) olarak İ&ccedil;imino &Ccedil;ocuk Oyunu kapsamında sizin ve &ccedil;ocuklarınızın veri sorumlusu sıfatıyla işlediğimiz kişisel verilerinin g&uuml;venliğine &ouml;nem veriyoruz. Bu nedenle sizleri sizin ve &ccedil;ocuklarınızın kişisel verilerinin ilgili mevzuat &ccedil;er&ccedil;evesinde &ccedil;eşitli şekillerde toplanması, işlenmesi ve yurt dışına aktarılmasına ilişkin olarak 6698 sayılı Kişisel Verilerin Korunması Kanunu (Bundan sonra "KVKK" olarak anılacaktır) ve Aydınlatma Y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n&uuml;n Yerine Getirilmesine Uyulacak Usul ve Esaslar Hakkında Tebliğ &ccedil;er&ccedil;evesinde<em> bilgilendirmek isteriz. </em></p>
                <p>Kişisel Verilerin Toplanma Y&ouml;ntemi ve Hukuki Sebebi</p>
                <p>İ&ccedil;imino &Ccedil;ocuk Oyunu kapsamında sizin ve &nbsp;yasal temsilcisi olduğunuz &ccedil;ocuklarınızın kişisel verileri, T.C. Anayasası, &uuml;lkemizin taraf olduğu ilgili uluslararası s&ouml;zleşmeler ile KVKK ve ikincil d&uuml;zenlemelere uygun olarak İ&ccedil;imino &Ccedil;ocuk Oyunu'na kayıt ve &ccedil;ocukların profil oluşturma s&uuml;re&ccedil;lerinin ve d&uuml;zenlenecek yarışmalar ve &ouml;d&uuml;llere y&ouml;nelik s&uuml;re&ccedil;lerin y&uuml;r&uuml;t&uuml;lmesi ama&ccedil;ları doğrultusunda (i) bir s&ouml;zleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla s&ouml;zleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması ve (ii) ilgili kişinin temel hak ve &ouml;zg&uuml;rl&uuml;klerine zarar vermemek kaydıyla veri sorumlusunun meşru menfaatleri i&ccedil;in veri işlemenin zorunlu olması hukuki sebeplerine dayanılarak tamamen veya kısmen otomatik yollarla ya da veri kayıt sisteminin par&ccedil;ası olmak kaydıyla otomatik olmayan y&ouml;ntemler ile Ak Gıda nam ve hesabına veri işleyen sıfatıyla İstanbul Ticaret Sicili M&uuml;d&uuml;rl&uuml;ğ&uuml; nezdinde 271066 numarası ile kayıtlı bulunan tedarik&ccedil;imiz MullenLowe İstanbul Reklamcılık Anonim Şirketi tarafından toplanmaktadır.</p>
                <p>Sizin ve &ccedil;ocuklarınızın kişisel verilerinin yurt dışına aktarımı ise KVKK'nın 5. maddesi uyarınca a&ccedil;ık rızanıza dayanılarak ger&ccedil;ekleştirilecektir.</p>
                <p>Ak Gıda, &ccedil;ocukların kişisel verilerinin korunmasına b&uuml;y&uuml;k hassasiyet g&ouml;stermektedir. İnternet ortamında &ccedil;ocukların takibini yapmanın fiili g&uuml;&ccedil;l&uuml;ğ&uuml; g&ouml;z &ouml;n&uuml;nde bulundurulduğunda s&ouml;z konusu trafiğin mutlaka veli/vasi g&ouml;zetiminde y&uuml;r&uuml;t&uuml;lmesi, verilerin işlenmesine ilişkin olarak a&ccedil;ık rıza alınması gereken durumlarda a&ccedil;ık rızanın veli/vasi tarafından verilmesi gerekmektedir.</p>
                <p>İşlenen Kişisel Veriler ve İşleme Ama&ccedil;ları</p>
                <p>Siz ve yasal temsilcisi olduğunuz &ccedil;ocuklarınızın elde edilen ve işlenen kişisel verileri, Ak Gıda b&uuml;nyesinde bulunan departmanların farklı s&uuml;re&ccedil;leri kapsamında, siz ve &ccedil;ocuklarınızın kişisel verisinin yer aldığı veri kategorisine g&ouml;re değişkenlik g&ouml;stererek &ccedil;eşitli ama&ccedil;lar doğrultusunda yukarıda sayılan hukuka uygunluk sebeplerine dayanarak işlenmektedir. İ&ccedil;imino &Ccedil;ocuk Oyunu'na katılım ve oyun kapsamında d&uuml;zenlenecek yarışmalarla ilgili siz ve yasal temsilcisi olduğunuz &ccedil;ocuklarınızın işlenen kişisel veri kategorileriniz ile bunların işlenme ama&ccedil;ları aşağıda belirtilmiştir:</p>
                <table width="627">
                    <tbody>
                        <tr>
                            <td width="190">
                                <p>VERİ KATEGORİLERİ</p>
                            </td>
                            <td width="437">
                                <p>İŞLENME AMA&Ccedil;LARI</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="190">
                                <p>1)&nbsp;&nbsp;&nbsp; Kimlik bilgileri</p>
                                <p>-&nbsp;&nbsp;&nbsp;&nbsp; &Ccedil;ocuklarınızın Ad/Soyadı</p>
                                <p>-&nbsp;&nbsp;&nbsp;&nbsp; Ebeveyn Ad/Soyadı</p>
                            </td>
                            <td width="437">
                                <p>İ&ccedil;imino &Ccedil;ocuk Oyunu'na kayıt ve &ccedil;ocukların profil oluşturma s&uuml;re&ccedil;lerinin y&uuml;r&uuml;t&uuml;lmesi</p>
                                <p>İ&ccedil;imino &Ccedil;ocuk Oyunu kapsamında d&uuml;zenlenecek yarışmalar ve &ouml;d&uuml;llere y&ouml;nelik s&uuml;re&ccedil;lerin y&uuml;r&uuml;t&uuml;lmesi</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="190">
                                <p>2)&nbsp;&nbsp;&nbsp; İletişim Bilgileri</p>
                                <p>-&nbsp;&nbsp;&nbsp;&nbsp; E-posta adresi</p>
                                <p>-&nbsp;&nbsp;&nbsp;&nbsp; Telefon Numarası</p>
                                <p>-&nbsp;&nbsp;&nbsp;&nbsp; Adres</p>
                            </td>
                            <td width="437">
                                <p>İ&ccedil;imino &Ccedil;ocuk Oyunu'na kayıt ve &ccedil;ocukların profil oluşturma s&uuml;re&ccedil;lerinin y&uuml;r&uuml;t&uuml;lmesi</p>
                                <p>İ&ccedil;imino &Ccedil;ocuk Oyunu kapsamında d&uuml;zenlenecek yarışmalar ve &ouml;d&uuml;llere y&ouml;nelik s&uuml;re&ccedil;lerin y&uuml;r&uuml;t&uuml;lmesi</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="190">
                                <p>3)&nbsp;&nbsp;&nbsp; İ&ccedil;imino &Ccedil;ocuk Oyunu oynarken elde edilen puan ve oyunla ilgili diğer veriler</p>
                            </td>
                            <td width="437">
                                <p>İ&ccedil;imino &Ccedil;ocuk Oyunu kapsamında d&uuml;zenlenecek yarışmalar ve &ouml;d&uuml;llere y&ouml;nelik s&uuml;re&ccedil;lerin y&uuml;r&uuml;t&uuml;lmesi</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p>&nbsp;</p>
                <p>Kişisel Verilerin Aktarılması</p>
                <p>Ak Gıda, siz ve yasal temsilcisi olduğunuz &ccedil;ocuklarınızın kişisel verilerini, yukarıda belirtilen ama&ccedil;lar ve bilhassa veri depolama ama&ccedil;ları dahilinde yurt i&ccedil;inde (veri işleyen sıfatıyla) İstanbul Ticaret Sicili M&uuml;d&uuml;rl&uuml;ğ&uuml; nezdinde 271066 numarası ile kayıtlı bulunan, kayıtlı adresi "Vişnezade Mah.Şehit Mehmet Sok.No.9 Ma&ccedil;ka Residence Beşiktaş/İstanbul, T&uuml;rkiye" olan MullenLowe İstanbul Reklamcılık Anonim Şirketi ve MullenLowe'un yurt dışındaki iş ortaklarıyla (Frankfurt am Main, Almanya adresinde kain Digital Ocean) &nbsp;KVKK'nın ilgili h&uuml;k&uuml;mlerine uygun olarak paylaşabilir.&nbsp;</p>
                <p>Yukarıda sayılanların haricinde kanunen zorunlu olarak &ouml;ng&ouml;r&uuml;len diğer durumlarda yasal y&uuml;k&uuml;ml&uuml;l&uuml;klerin yerine getirilmesi maksadıyla, siz ve yasal temsilcisi olduğunuz &ccedil;ocuklarınızın kişisel verileri Ak Gıda tarafından yetkili kurumlar ile paylaşılabilir.</p>
                <p>Kişisel Verilerin Saklanması</p>
                <p>Siz ve yasal temsilcisi olduğunuz &ccedil;ocuklarınızın kişisel verileri KVKK kapsamında ilgili mevzuatta &ouml;ng&ouml;r&uuml;len veya işlendikleri ama&ccedil; i&ccedil;in gerekli olan makul s&uuml;reler boyunca muhafaza edilecektir.</p>
                <p>Kendiniz ve Yasal Temsilcisi Olduğunuz &Ccedil;ocuklarınız ile ilgili Kişisel Verilere İlişkin Haklarınız</p>
                <p>KVKK'nın "İstisnalar" başlıklı 28. maddesinde &ouml;ng&ouml;r&uuml;len haller saklı kalmak kaydıyla KVKK'nın 11. maddesinde d&uuml;zenlenen kendiniz ve yasal temsilcisi olduğunuz&nbsp; &ccedil;ocuklarınız &nbsp;ile ilgili haklarınız &ccedil;er&ccedil;evesinde ilgili kişi sıfatıyla Ak Gıda'ya başvurarak;</p>
                <p>&Oslash;&nbsp; Sizin ve/veya &ccedil;ocuklarınızın kişisel verilerinin işlenip işlenmediğini &ouml;ğrenme,</p>
                <p>&Oslash;&nbsp; Sizin ve/veya &ccedil;ocuklarınızın kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</p>
                <p>&Oslash;&nbsp; Sizin ve/veya &ccedil;ocuklarınızın kişisel verilerinin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını &ouml;ğrenme,</p>
                <p>&Oslash;&nbsp; Yurt i&ccedil;inde veya yurt dışında sizin ve/veya &ccedil;ocuklarınızın kişisel verilerinin aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişileri bilme,</p>
                <p>&Oslash;&nbsp; Sizin ve/veya &ccedil;ocuklarınızın kişisel verilerinin eksik veya yanlış işlenmiş olması h&acirc;linde bunların d&uuml;zeltilmesini isteme ve bu kapsamda yapılan işlemlerin kişisel verilerin aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişilere bildirilmesini isteme,</p>
                <p>&Oslash;&nbsp; İşlenmesini gerektiren sebeplerin ortadan kalkması h&acirc;linde sizin ve/veya &ccedil;ocuklarınızın kişisel verilerinin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemlerin kişisel verilerin aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişilere bildirilmesini isteme,</p>
                <p>&Oslash;&nbsp; İşlenen verilerin m&uuml;nhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonucun ortaya &ccedil;ıkmasına itiraz etme,</p>
                <p>&Oslash;&nbsp; KVKK'nın 7. maddesinde yer alan şartlar &ccedil;er&ccedil;evesinde kişisel verilerin silinmesini ya da yok edilmesini isteme, yapılan d&uuml;zeltme, silme ya da yok etme işlemlerinin kişisel verilerin paylaşıldığı &uuml;&ccedil;&uuml;nc&uuml; kişilere bildirilmesini talep etme ve</p>
                <p>&Oslash;&nbsp; Sizin ve/veya &ccedil;ocuklarınızın kişisel verilerinin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız h&acirc;linde zararın giderilmesini talep etme haklarına sahipsiniz.</p>
                <p>Yukarıda sayılan kendiniz ve yasal temsilcisi olduğunuz &ccedil;ocuklarınız ile ilgili haklarınıza ilişkin taleplerinizi Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ'de &ouml;ng&ouml;r&uuml;len başvuru usullerine uygun olarak Ak Gıda&rsquo;nın kurumsal iletişim mail adresi olan info@akgida.com.tr'ye iletmeniz durumunda Ak Gıda talebinizi niteliğine g&ouml;re en kısa s&uuml;rede ve en ge&ccedil; 30 (otuz) g&uuml;n i&ccedil;inde &uuml;cretsiz olarak sonu&ccedil;landıracaktır. Ancak, işlemin ayrıca bir maliyeti gerektirmesi h&acirc;linde, Ak Gıda Kişisel Verileri Koruma Kurulunca belirlenen tarifedeki &uuml;creti talep edebilecektir.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={toggleShow}>
                    Tamam
                </Button>
            </Modal.Footer>
        </Modal>
    )
};
export default InfoModal;