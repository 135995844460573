import React, { useState } from 'react';
import {Modal,Button} from 'react-bootstrap';


const ContsentModal = (props) => {
    const {showConsent, toggleShow} = props
    return (
    <Modal className="consent-modal" show={showConsent} onHide={toggleShow}>
        <Modal.Header closeButton>
            <Modal.Title>AÇIK RIZA METNİ
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p>İ&ccedil;imino &Ccedil;ocuk Oyunu'na kayıt, profil oluşturma s&uuml;re&ccedil;leri ve oyun kapsamında d&uuml;zenlenecek olan yarışmalar ve verilecek &ouml;d&uuml;ller &ccedil;er&ccedil;evesinde veri sorumlusu Ak Gıda'nın benim ve yasal temsilcisi olduğum &ccedil;ocuk/&ccedil;ocukların kişisel verilerinin <em>İ&ccedil;imino &Ccedil;ocuk Oyunu Kapsamında Kişisel Verilerin İşlenmesine ve Yurt Dışına Aktarılmasına Dair Aydınlatma Metni</em>'nde belirtilen ama&ccedil;lar ve h&uuml;k&uuml;mler dahilinde yurt dışında kain şirketlere aktarılmasına a&ccedil;ık rıza veriyorum.</p>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={toggleShow}>
                Tamam
            </Button>
        </Modal.Footer>
    </Modal>
    )
};
export default ContsentModal;