import React from 'react';
import ReactDOM from 'react-dom';
import {Router, Route, Switch, Redirect} from 'react-router';
import {createBrowserHistory} from 'history';
import {PrivateRoute} from './helpers/privateRoute';
import Login from './pages/Login';
import Register from './pages/Register';
import Profile from './pages/Profile';
import PointTable from './pages/PointTable';
import Characters from './pages/Characters';
import './assets/styles/index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import GamingPage from './pages/GamingPage';
import PasswordChange from './pages/PasswordChange';
import ResetPasswordClaim from './pages/ResetPasswordClaim';
import ResetPassword from './pages/ResetPassword';

import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

ReactGA.initialize('UA-64197696-2');
ReactGA.pageview(window.location.pathname + window.location.search);

ReactPixel.init('280230830847365', {}, {autoConfig: true});
ReactPixel.pageView();

export const history = createBrowserHistory({
  forceRefresh: true,
});

ReactDOM.render(
  <Router history={history}>
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/home" component={Characters} />
      <PrivateRoute exact path="/profile" component={Profile} />
      <PrivateRoute exact path="/point_table" component={PointTable} />
      <Route exact path="/gaming_page" component={GamingPage} />
      <PrivateRoute exact path="/password_change" component={PasswordChange} />
      <Route exact path="/reset_password_claim" component={ResetPasswordClaim} />
      <Route exact path="/reset_password" component={ResetPassword} />
      <Redirect from="*" to="/home" />
    </Switch>
  </Router>,
  document.getElementById('root')
);
