import React, {useState} from 'react';
import {Input, Button} from 'antd';
import {resetPasswordClaim} from '../apis/utils';
import Header from '../components/Headers/header';
import Footer from '../components/Footers/footer';
import formHeader from '../assets/images/resetPassword.svg';

const ResetPasswordClaim = () => {
  const [inputs, setInputs] = useState({});

  const handleInputsChange = (event) => {
    event.persist();
    setInputs((inputs) => ({...inputs, [event.target.id]: event.target.value}));
  };

  const handleSubmit = () => {
    let data = {
      email: inputs.email,
    };
    resetPasswordClaim(data);
  };

  return (
    <div className="entrance-bg h-100">
      <Header />
      <div className="height-85 mb d-flex justify-content-center align-items-center">
        <div className="form__panel">
          <img className="form__panel-image " src={formHeader} />
          <label className="label">Email</label>
          <Input id="email" onChange={handleInputsChange} className="mb-3 form__input" />
          <div className="d-flex justify-content-center my-4">
            <Button className="form__button" onClick={handleSubmit}>
              Gönder
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ResetPasswordClaim;
