import React, { useState } from 'react';
import { Input, Button } from 'antd';
import { loginRequest } from '../apis/utils';
import Header from '../components/Headers/header';
import Footer from '../components/Footers/footer';
import { Link } from 'react-router-dom';
import loginText from '../assets/images/loginText.svg';

const Login = () => {
  const [inputs, setInputs] = useState({});
  const [emailErrors, setEmailErrors] = useState({});

  const validateEmail = (email) => {
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,2})+$/
    return re.test(email);
  };

  const handleInputsChange = (event) => {
    event.persist();
    setInputs((inputs) => ({ ...inputs, [event.target.id]: event.target.value }));
    if (!validateEmail(inputs.email) && event.target.id === 'email') {
      setEmailErrors({ visibility: true, message: 'Geçerli bir mail adresi girin.' });
    } else {
      setEmailErrors({ visibility: false });
    }
  };

  const handleSubmit = () => {
    console.warn("validateEmail(inputs.email) >> ", validateEmail(inputs.email), !inputs.password);
    if(validateEmail(inputs.email) || !inputs.password){
      alert("Email ve Şifre girin");
      return;
    }
    let score = localStorage.getItem('score') || null;
    let data = {
      email: inputs.email,
      password: inputs.password,
      score,
    };
    loginRequest(data);
  };

  return (
    <div className="entrance-bg h-100">
      <Header isVisible />
      <div className="height-85 mb d-flex justify-content-center align-items-center">
        <div className="form__panel">
          <img className="form__panel-image " src={loginText} />
          <Input
            id="email"
            onChange={handleInputsChange}
            className="mb-3 form__input"
            placeholder="Email"
          />
          {emailErrors.visibility && <p>{emailErrors.message}</p>}
          <Input
            id="password"
            onChange={handleInputsChange}
            className="mb-3 form__input"
            placeholder="Şifre"
            type="password"
          />
          <Link to="reset_password_claim">Şifremi unuttum</Link>
          <div className="d-flex justify-content-center my-4">
            <Button className="form__button" onClick={handleSubmit}>
              Giriş yap
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
