import axios from 'axios';

export const baseUrlWithoutToken = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  baseURL: 'https://iciminoileoyna.com/api/',
});

export const baseUrlWithToken = axios.create({
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    'Content-Type': 'application/json',
  },
  baseURL: 'https://iciminoileoyna.com/api/',
});
