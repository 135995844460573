import React, {useEffect, useState} from 'react';
import Header from '../components/Headers/header';
import Unity, {UnityContext} from 'react-unity-webgl';
import {isMobile} from 'react-device-detect';
import {useComponentWillMount} from '../helpers/customHooks';
import DeviceOrientation, {Orientation} from 'react-screen-orientation';

let unityContent;
const handleBuildSelector = (character) => {
  console.log(`inside the gasdfsdfsming page`);
  if (character == 'strawberry') {
    return {
      codeUrl: 'Cilek/Icim_Cilek.wasm',
      loaderUrl: 'Cilek/Icim_Cilek.loader.js',
      dataUrl: 'Cilek/Icim_Cilek.data',
      frameworkUrl: 'Cilek/Icim_Cilek.framework.js',
    };
  } else if (character == 'chocolate') {
    return {
      codeUrl: 'Ciko/Icim_Cikolata.wasm',
      loaderUrl: 'Ciko/Icim_Cikolata.loader.js',
      dataUrl: 'Ciko/Icim_Cikolata.data',
      frameworkUrl: 'Ciko/Icim_Cikolata.framework.js',
    };
  } else {
    return {
      codeUrl: 'Muz/Icim_Muz.wasm',
      loaderUrl: 'Muz/Icim_Muz.loader.js',
      dataUrl: 'Muz/Icim_Muz.data',
      frameworkUrl: 'Muz/Icim_Muz.framework.js',
    };
  }
};
const GamingPage = ({location}) => {
  const [character, setCharacter] = useState('');
  const [headerVisiblity, setHeaderVisiblity] = useState(true);
  const [gameLoaded, setGameLoaded] = useState(true);
  const [progression, setProgression] = useState(0);

  useComponentWillMount(() => {
    console.log(`location.state ${location.state && location.state.selectedCharacters}`);
    let selectedChar = location.state ? location.state.selectedCharacters : 'banana';
    setCharacter(selectedChar);
    console.log(selectedChar);
    const {codeUrl, loaderUrl, dataUrl, frameworkUrl} = handleBuildSelector(selectedChar);
    unityContent = new UnityContext({
      codeUrl,
      loaderUrl,
      dataUrl,
      frameworkUrl,
    });
  });

  useEffect(() => {
    setGameLoaded(false);
    if (isMobile) {
      setHeaderVisiblity(false);
    }
    unityContent.on('progress', (progression) => {
      setProgression(Math.trunc(progression * 100));
    });

    unityContent.on('loaded', () => {
      setGameLoaded(true);
    });
  }, []);

  return (
    <>
      <div id="sdf" className={`h-100 ${character}-frame`}>
        <Header
          isVisible={headerVisiblity}
          isAuth={localStorage.getItem('token') ? true : false}
          isGamingPage
        />
        <div className="h-100 mb d-flex justify-content-center align-items-center">
          {isMobile ? (
            <>
              <div className={`game-panel-${character}`}>
                <div id="game-container" className="game-container">
                  {!gameLoaded && (
                    <div className="game-container loading-screen">
                      <p>Loading ... {progression}%</p>
                    </div>
                  )}
                  <Unity unityContext={unityContent} />
                </div>
              </div>
              <div className="game-warning">
                <p className="text-center text-fs24 text-white">
                  İçimino ile eğlenceye katılabilmek için önce cihazını yatay konuma
                  getirmelisin
                </p>
              </div>
            </>
          ) : (
            <div className={`game-panel-${character}`}>
              <div id="game-container" className="game-container">
                {!gameLoaded && (
                  <div className="game-container loading-screen">
                    <p>Loading ... {progression}%</p>
                  </div>
                )}
                <Unity unityContext={unityContent} />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default GamingPage;
